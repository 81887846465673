import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import Modal from '../../../components/components/PuntoVentaModal';
import FacturacionPosDetalle from './FacturacionPosDetalle';

// Elementos del formulario
import {
  renderField,
  renderNumber,
  renderFieldCheck,
  renderFieldCheckB,
  renderFieldRadio,
  AsyncSelectField,
  AsyncSelectFieldIn,
  renderCurrencyPoliza,
  renderCurrencyFloat,
  renderCurrencyFloatA,
  SelectField,
  renderFieldPoliza,

} from "../../Utils/renderField/renderField";
import "../../../../../style/PuntoVenta/index.css";
import { FaArrowAltCircleLeft, FaCashRegister, FaMoneyBillAlt, FaPlusCircle, FaSave, FaTimes } from 'react-icons/fa';
import { productosList } from './dataproductos';
import { useEffect } from 'react';

function FacturaPosForm(props) {
  const {
    crear,
    // lectura,
    // handleSubmit,
    modulo,
    listarProductos,
    // listarClientes,
    registroCliente,
    listadoPaises,
    listarPrecios,
    datos,
    // detalleProducto,
    // eliminarProducto,
    registroProductoInventario,
    periodoD,

  } = props;

  console.log( datos );
  console.log( listadoPaises );
  // MODALS
  const [estadoModalCliente, setEstadoModalCliente] = useState(false);
  const [estadoModalCorteCaja, setEstadoModalCorteCaja] = useState(false);
  const [estadoModalFormaPago, setEstadoModalFormaPago] = useState(false);
  const handleShowModalCliente = () => setEstadoModalCliente(true);
  const handleShowModalCorteCaja = () => setEstadoModalCorteCaja(true);
  const handleShowModalFormaPago = () => setEstadoModalFormaPago(true);
  const handleCloseModalCliente = () => setEstadoModalCliente(false);
  const handleCloseModalCorteCaja = () => setEstadoModalCorteCaja(false);
  const handleCloseModalFormaPago = () => setEstadoModalFormaPago(false);


  const [productos, setProductos] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [image, setImage] = useState('');
  const [isActiveList, setIsActiveList] = useState(false)
  const [focus, setFocus] = useState(true)
  const [filter, setFilter] = useState('');

  const [total, setTotal] = useState(0.0);
  const [cantidad, setCantidad] = useState(0.00);
  const [iva, setIva] = useState(0.00);

  
  const [pagos, setPagos] = useState(
    [
      { id: "1", tipo: "Efectivo", autorizacion: "0", importe: "125.00" },
      { id: "2", tipo: "Tarjeta Credito", autorizacion: "1234566", importe: "125.00" },
      { id: "3", tipo: "Tarjeta Débito", autorizacion: "1234566", importe: "125.00" },
      { id: "4", tipo: "Transferencia", autorizacion: "1234566", importe: "125.00" },
    ]
  );

  const tipos = [
    {
      value: 'L',
      label: 'LOCAL'
    },
    {
      value: 'E',
      label: 'EXTERIOR'
    },
    {
      value: 'P',
      label: 'PERSONA'
    },
  ];

  const [activo, setActivo] = useState(true);
  const onCheckboxClicked = (idx, isChecked) => {
    setActivo(isChecked);
  }


  useEffect(() => {
    setProductos(productosList);
  }, [])

  const filteredProducts = productos.filter((product) =>
    product.nombre.toLowerCase().includes(filter.toLowerCase())
  );

  const deleteItemProduct = (id) => {
    const newDetails = detalle.filter((item) => item.id !== id)
    setDetalle(newDetails);
  }

  const deleteItemPago = (id) => {
    const newPagos = pagos.filter((item) => item.id !== id)
    setPagos(newPagos);
  }


  // Agregar producto al carrito
  const productSelected = (id) => {
    if (id) {

      const [item] = detalle.filter((item) => item.id === id)
      if (!item) {
        const [product] = productos.filter((item) => item.id === id)
        const newItem = {
          id: product.id,
          nombre: product.nombre,
          cantidad: 1,
          precio: product.precio,
          total: product.precio * 1
        }
        setImage(""),
        setTotal(newItem.total),
        setDetalle([newItem, ...detalle])
      } else {
        item.cantidad = item.cantidad + 1
        item.total = item.cantidad * item.precio
      }
    }
    // return
  }

  const imageSelected = (id) => {
    const [product] = productos.filter((item) => item.id === id)
    if (!id) {
      return
    }
    setImage(product)
  }


  const editar = window.location.href.includes('editar');
  let imagenActive = true;
  let titulo = editar ? 'Modificar Venta' : 'Crear Venta';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Venta';
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  const formaPago = [
    {
      value: 'EF',
      label: 'EFECTIVO'
    },
    {
      value: 'TD',
      label: 'TARJETA DEBITO'
    },
    {
      value: 'TC',
      label: 'TARJETA CREDITO'
    },
    {
      value: 'TE',
      label: 'TRANSFERENCIA'
    },
  ];

  // DOBLE CLIK
  let cliks = [];
  let time = "";

  const onDobleClickSelect = (id) => {
    cliks.push(new Date().getTime());
    window.clearTimeout(time);
    time = window.setTimeout(() => {
      if (cliks.length > 1 && (cliks[cliks.length - 1] - cliks[cliks.length - 2]) < 500) {
        // Codigo
        if (id === "") {
          return
        }
        if (id) {
          productSelected(id);
          setFilter("");
          setIsActiveList(false);
        }

      }
    }, 500)
  }

  // STYLES
  const heithPos = {
    height: "60vh",
  }
  const estilo = {
    background: "#333",
  };
  const styleSelect = {
    height: "200px",
    border: "none",
    "margin-left": "-10px",
  }

  const handleSubmitFacturacion = (e) => {
    e.preventDefault
    console.log("data");
  }

  const handleSubmitCliente = (e) => {
    e.preventDefault
    console.log("data");
  }

  const handleSubmitCorteCaja = (e) => {
    e.preventDefault
    console.log("data");
  }

  const handleSubmitFormaPago = (e) => {
    e.preventDefault
    console.log("data");
  }

  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      {/* ************ PUNTO DE VENTA **************  */}
      <div className='row h-100 m-0 mt-2 text-white rounded' style={estilo} >
        {/* Cuando los buttons van dentro de la seccion de productos se colca "clase: pb-4" de lo contrario mo se coloca nada */}
        <div className='row m-0' >
          {/* BUSCAR PRODUCTO Y DETALLE PRODUCTOS */}
          <div className="col-12 col-lg-8 py-3">
            <div className="card p-3 bg-dark text-white h-100 rounded">
              <div className="row">
                <div className="col-12">
                  <h4 className="text-white fs-6 d-flex justify-content-around align-items-center mt-2">PUNTO DE VENTA</h4>
                  <div className="row">
                    {/* Input para ingresar producto */}
                    <div className="col-12 col-md-12">
                      <div className="row">
                        <div className="col-12">
                          <label className="fw-bold">INGRESE PRODUCTO
                          </label>
                          <input
                            autoFocus
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (e.target.value === "") {
                                  return (
                                    setIsActiveList(false),
                                    setFilter(""),
                                    setFocus(true),
                                    imageSelected(null)
                                  )
                                }
                                if (filteredProducts.length < 1) {
                                  return (
                                    setIsActiveList(false),
                                    setFilter(""),
                                    imageSelected(null)
                                  )
                                }
                                setFilter(e.target.value);
                                if (filteredProducts) {
                                  // productSelected(filteredProducts[0].id);
                                  setIsActiveList(false)
                                }
                                setFocus(true)
                                e.target.value = "";
                                setFilter("");
                                imageSelected(null)
                              }
                            }}
                            value={filter}
                            onChange={(e) => {
                              if (e.target.value.length < 1) {
                                return (
                                  setFilter(""),
                                  setIsActiveList(false),
                                  productSelected(null),
                                  imageSelected(null)
                                )
                              }
                              setFilter(e.target.value)
                              setIsActiveList(true)
                            }}
                            type="text"
                            placeholder="Ingrese el código de barras o el nombre del producto"
                            className="form-control form-control-sm py-2 px-2"
                            id="iptCodigoVenta"
                            name="iptCodigoVenta"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />

                        </div>
                      </div>
                    </div>

                    {/* Select producto */}
                    {isActiveList &&
                      <div className="col-12 col-md-12">
                        <div className="row border m-0 rounded-bottom ">
                          <div className="col-12 col-lg-8">
                            {filteredProducts &&
                              <select
                                style={styleSelect}
                                multiple={true}
                                onClick={(e) => onDobleClickSelect(e.target.value)}
                                onBlur={(e) => {
                                  return (
                                    e.target.value = "",
                                    setImage(""),
                                    setIsActiveList(true)
                                  )
                                }}
                                onChange={(e) => {
                                  if (filteredProducts.length < 1) {
                                    return (
                                      setIsActiveList(false),
                                      setFilter(""),
                                      productSelected(null)
                                    )
                                  }
                                  setIsActiveList(true)
                                  imageSelected(e.target.value);
                                }}

                                onKeyDown={(e) => {
                                  if (e.target.value === "") {
                                    return
                                  }
                                  if (e.key === "Enter") {
                                    productSelected(e.target.value);
                                    e.target.value = "";
                                    setFocus(true);
                                    setFilter("");
                                    setIsActiveList(false);
                                  }
                                }}
                                className="form-select bg-dark text-white"
                                aria-label="Default select example"
                              >
                                {filteredProducts.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                  > {`${item.nombre}`}
                                  </option>
                                ))}
                              </select>
                            }
                          </div>
                          {image &&
                            <div className="col-12 col-lg-4 text-center">
                              <img style={{ height: "190px", width: "200px", margin: "auto" }} src={image.url} className="rounded text-center border-secondary p-2" alt={image.nombre} />
                            </div>
                          }
                        </div>
                      </div>
                    }

                    {/* Listado de productos al carrito*/}
                    {!isActiveList &&
                      <div className="col-12 mt-2">
                        <div className="row">
                          <div className="col-12 table-responsive">
                            <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                              <thead className="bg-main">
                                <tr>
                                  <th>Codigo</th>
                                  <th>Descripcion</th>
                                  <th className="text-center">Cantidad</th>
                                  <th className="text-center">Precio</th>
                                  <th className="text-center">Total</th>
                                  <th className="text-center">Acciones</th>
                                </tr>
                              </thead>
                              <tbody className="small text-left">
                                {detalle.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.nombre}</td>
                                    <td className="text-center">{item.cantidad}</td>
                                    <td className="text-center">{item.precio}</td>
                                    <td className="text-center">{item.total}</td>
                                    <td className="text-center">
                                      <button className="btn-success text-lg bg-dark text-white px-2" onClick={(e) => deleteItemProduct(item.id)} >X</button>
                                    </td>
                                  </tr>
                                ))}
                                {detalle.length < 1 &&
                                  <tr>
                                    <td colSpan={6} className="text-center">No hay productos</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <br />
                          {/* Resumen totales */}
                          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-end align-items-center mt-2">
                            <div className="float-right">
                              <div className="row">
                                <div className="">
                                  <div className="row">
                                    <div className="col-12 col-md-12 d-flex justify-content-between">
                                      <span>SUBTOTAL</span>
                                      <span className="float-right" id="resumen_subtotal">Q/ 0.00</span>
                                    </div>
                                    <div className="col-12 col-md-12 d-flex justify-content-between">
                                      <span>IVA</span>
                                      <span className="float-right" id="resumen_total_igv">Q/ 0.00</span>
                                    </div>
                                    <hr className="col-11 col-md-11 mr-1" />
                                    <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                                      <span>TOTAL</span>
                                      <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Botones */}
                    <div className='col-12 col-lg-12 mt-2'>
                      <br></br>
                      <br></br>
                      <div className="col-12">
                        <div className="d-flex justify-content-center gap-3">
                          <button
                            className='btn mr-2 btn-primary'
                            onSubmit={handleSubmitFacturacion}
                          >
                            <FaSave />  Guardar
                          </button>
                          <button
                            className="btn mr-2 btn-secondary">
                            <FaTimes />  Cancelar
                          </button>
                          <a href={`/#/punto_venta/${id_emp[5]}/`}
                            className='btn mr-2 btn-danger text-lg'
                          >
                            <FaArrowAltCircleLeft />  Salir
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Datos del cliente y formas de pago */}
          <div className="col-12 col-lg-4 py-3">
            <div className="card p-3 bg-dark text-white h-100 rounded">
              <div className="row">
                {/* Cuadre de caja */}
                <div className="col-12 text-center">
                  <div className="col-12 d-flex justify-content-center text-center">
                    <h4 className='text-secondary'>
                      Saldo en caja
                      <span className='text-white'> Q 2,500.00 </span>
                      <br></br>
                      <button
                        className='btn btn-secondary'
                        onClick={handleShowModalCorteCaja}
                      >
                        <FaCashRegister />  Realizar cuadre
                      </button>
                    </h4>
                  </div>
                </div>

                {/* Datos del cliente */}
                <div className="col-12">
                  <div className="col-12 p-3 text-center">
                    <h4 className='text-secondary'>
                      <span>Datos del cliente  </span>
                      <button
                        className="btn btn-primary"
                        onClick={handleShowModalCliente}
                      >
                        <FaPlusCircle />  Agregar
                      </button>
                    </h4>
                    <div className="row border rounded ">
                      <label htmlFor="nitcliente" className="col-12 text-center col-lg-4 col-form-label text-lg-end">Nit del cliente:</label>
                      <div className="col-12 col-lg-8 align-content-center">
                        <input type="text" className="text-center text-lg-left form-control bg-dark border-0 text-white fw-bold" name="nitcliente" defaultValue="1234594-9" />
                      </div>
                    </div>
                    <div className="row border rounded text-center bg-secondary text-white">
                      <label htmlFor="nombrecliente" className="col-12 col-lg-4 col-form-label text-center text-lg-end">Nombre:</label>
                      <div className="col-12 text-center col-lg-8">
                        <input type="text" readOnly className="text-center text-lg-left form-control-plaintext text-white fw-bold" name="nombrecliente" defaultValue="Juan Carlos Pineda" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Formas de pago */}
                {/* <!-- LISTADO Y RESUMEN FORMA PAGO --> */}
                <div className="col-12 mt-3">
                  <div className="col-12 table-responsive">
                    <div className="col-12 text-center">
                      <h4 className='text-secondary'>
                        <span>Forma de pago </span>
                        <button
                          className="btn btn-primary"
                          onClick={handleShowModalFormaPago}
                        >
                          <FaPlusCircle />  Agregar
                        </button>
                      </h4>
                    </div>
                    <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                      <thead className="bg-main text-center">
                        <tr>
                          <th>Tipo</th>
                          <th>Autorozación</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="small text-center">
                        {pagos.map((item) => (
                          <tr key={item.id}>
                            <td>{item.tipo}</td>
                            <td>{item.autorizacion}</td>
                            <td className="text-center">{item.importe}</td>
                            <td className="text-center">
                              <button className="btn-secondary text-lg bg-dark text-white px-1" onClick={(e) => deleteItemPago(item.id)} >X</button>
                            </td>
                          </tr>
                        ))}
                        {pagos.length < 1 &&
                          <tr>
                            <td colSpan={6} className="text-center">No hay pagos</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Totales */}
                  <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-center align-items-center mt-2">
                    <div className="float-center">
                      <div className="row">
                        <hr className="m-1" />
                        <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                          <span>TOTAL</span>
                          <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Botones */}
          {/* <div className='col-12 col-lg-12 mt-2   '>
            <div className="card p-3 my-3 bg-dark text-white rounded">
              <div className="d-flex justify-content-center gap-3">
                <button
                  className='btn mr-2 btn-primary'
                >
                  Guardar
                </button>
                <button
                  className="btn mr-2 btn-secondary">
                  Cancelar
                </button>
                <a href={`/#/`}
                  className='btn mr-2 btn-danger text-lg'
                >
                  Salir
                </a>
              </div>
            </div>
          </div> */}
        </div>

      </div>
      {/* ************ MODAL CLIENTE **************  */}
      <Modal
        estadoModal={estadoModalCliente}
        handleClose={handleCloseModalCliente}
        titulo="Nuevo cliente"
        layoutColumn={6} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            <div className="col-12">
              <div className="row">
                {/* Identificador */}
                <div className="mb-2 col-md-4">
                  <Field
                    autoFocus={autoFocus}
                    name="identificadorCliente"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={editar ? true : disabled}
                    msj="Identificador"
                  />
                </div>
              </div>
              <div className="row">
                {/* Nombre */}
                <div className="mb-2 col-md-8">
                  <Field
                    name="nombreCliente"
                    placeholder="nombreCliente"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Nombre"
                  />
                </div>
                {/* Pais */}
                {listadoPaises &&
                  <div className="mb-2 col-md-4">
                    <div className="form-floating">
                      <Field
                        name="paisCliente"
                        options={listadoPaises}
                        crear={crear}
                        dato={0}
                        component={SelectField}
                        className="form-select"
                        disabled={disabled}
                        msj="Pais"
                      />
                    </div>
                  </div>
                }
              </div>
              <div className="row">
                {/* Direccion */}
                <div className="mb-2 col-md-8">
                  <Field
                    name="direccionCliente"
                    placeholder="Direccion"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Dirección"
                  />
                </div>
                {/* Telefono */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="telefonoCliente"
                    placeholder="Telefono"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Telefono"
                  />
                </div>
              </div>
              <div className="row">
                {/* Correo */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="correoCliente"
                    placeholder="Correo electrónico"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Correo electronico"
                  />
                </div>
                {/* Dias credito */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="diasCredito"
                    placeholder="Dias credito"
                    className="form-control"
                    component={renderNumber}
                    disabled={disabled}
                    msj="Dias crédito"
                  />
                </div>
                {/* Limite Credito */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="limiteCredito"
                    placeholder="Limite credito"
                    className="form-control"
                    component={renderNumber}
                    disabled={disabled}
                    msj="Limite crédito"
                  />
                </div>
              </div>
              <div className="row">
                {/* Tipo Cliente */}
                <div className="mb-2 col-md-4">
                  {(crear === true) &&
                    <div className="form-floating">
                      <Field
                        name="tipoCliente"
                        options={tipos}
                        dato={0}
                        component={SelectField}
                        className="form-select"
                        disabled={disabled}
                        msj="Tipo cliente"
                      />
                    </div>
                  }
                </div>
                {/* Status */}
                <div className="mb-2 col-md-2">
                  {crear === true ? (
                    <Field
                      name="statusCliente"
                      label="Activo"
                      checked={activo}
                      onChange={onCheckboxClicked}
                      component={renderFieldCheckB}
                    />
                  ) :
                    <Field
                      disabled={disabled}
                      name="activo"
                      label="Activo"
                      component={renderFieldCheck}
                    />
                  }
                </div>
                {/* Lista precio Disable */}
                <div className="mb-2 col-md-6">
                  <Field
                    name="listadoPreciosCliente"
                    placeholder="Listado de precios..."
                    className="form-control-find"
                    component={AsyncSelectField}
                    loadOptions={listarPrecios}
                    msj="Listado de precios"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={() =>registroCliente(datos)}
          >
            <FaSave />  Guardar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalCliente}
          >
            <FaArrowAltCircleLeft />  Salir
          </button>
        </div>
      </Modal>

      {/* ************ MODAL CORTE CAJA **************  */}
      <Modal
        estadoModal={estadoModalCorteCaja}
        handleClose={handleCloseModalCorteCaja}
        titulo="Detalle Corte de caja"
        layoutColumn={5} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            <div className="col-12">
              <h5 className='text-center fw-bold'>{`Del ${new Date().toLocaleDateString()} al ${new Date().toLocaleDateString()} `}</h5>
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS EN EFECTIVO</span>
                        <span className="float-right" id="resumen_subtotal">Q/ 500.00</span>
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS CON TARJETA</span>
                        <span className="float-right" id="resumen_total_igv">Q/ 2,500.00</span>
                      </div>
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>TOTAL VENTAS</span>
                        <span className="float-right " id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>EFECTIVO AL INICIO</span>
                        <span className="float-right" id="resumen_subtotal">Q/ 500.00</span>
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS EN EFECTIVO</span>
                        <span className="float-right" id="resumen_total_igv">Q/ 2,500.00</span>
                      </div>
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>TOTAL EFECTIVO</span>
                        <span className="float-right " id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Importe efectivo */}
                <label htmlFor="efectivocontado" className="col-12 col-lg-8 col-md-8 col-form-label">EFECTIVO AL FINAL</label>
                <div className="col-12 col-md-4 col-lg-4 py-0 fs-6  ">
                  <Field
                    name="efectivocontado"
                    placeholder="Efectivo"
                    className="form-control px-3 m-0 fs-6"
                    component={renderCurrencyPoliza}
                    disabled={disabled}
                    msj="Efectivo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>DIFERENCIA</span>
                        <span className="float-right " id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={handleSubmitCorteCaja}
          >
            <FaSave />  Guardar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalCorteCaja}
          >
            <FaArrowAltCircleLeft />  Salir
          </button>
        </div>
      </Modal>


      {/* ************ MODAL FORMA PAGO **************  */}
      <Modal
        estadoModal={estadoModalFormaPago}
        handleClose={handleCloseModalFormaPago}
        titulo="Forma de pago"
        layoutColumn={5} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            {/* <!-- LISTADO Y RESUMEN FORMA PAGO --> */}
            <div className="col-12 table-responsive">
              <div className="col-12 text-center">
                <h4 className='text-secondary'>
                  Total a pagar
                  <span className='text-white'> Q 2,500.00 </span>
                </h4>
              </div>
              <div className="row">
                {/* Forma pago */}
                {formaPago &&
                  <div className="col-md-5 px-2">
                    <div className="form-floating">
                      <Field
                        autoFocus
                        name="formapago"
                        options={formaPago}
                        crear={crear}
                        dato={0}
                        component={SelectField}
                        className="form-select"
                        disabled={disabled}
                        msj="Forma pago"
                      />
                    </div>
                  </div>
                }
                {/* Autorizacion */}
                <div className="col-md-4 px-2">
                  <Field
                    name="autorizacion"
                    placeholder="Autorización"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Autorizacion"
                  />
                </div>
                {/* Importe */}
                <div className="col-md-3 px-2">
                  <Field
                    name="Importe"
                    placeholder="Importe"
                    className="form-control"
                    component={renderCurrencyFloat}
                    disabled={disabled}
                    msj="Importe"
                  />
                </div>
              </div>
              <div className="col-12 text-center py-3">
                <button type="button" className="btn btn-primary mx-1 " onClick={handleSubmitFormaPago}>
                  <FaPlusCircle />  Agregar
                </button>
              </div>

              <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                <thead className="bg-main text-center">
                  <tr>
                    <th>Tipo</th>
                    <th>Autorozación</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="small text-center">
                  {pagos.map((item) => (
                    <tr key={item.id}>
                      <td>{item.tipo}</td>
                      <td>{item.autorizacion}</td>
                      <td className="text-center">{item.importe}</td>
                      <td className="text-center">
                        <button className="btn-secondary text-lg bg-dark text-white px-1" onClick={(e) => deleteItemPago(item.id)} >X</button>
                      </td>
                    </tr>
                  ))}
                  {pagos.length < 1 &&
                    <tr>
                      <td colSpan={4} className="text-center">No hay pagos</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            {/* Totales */}
            <div className="col-12 col-lg-12 col-md-12 mt-2">
              <div className="float-center text-center">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <hr className='my-0' />
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between">
                    <span>TOTAL A PAGAR</span>
                    <span className="float-right" id="resumen_subtotal">Q/ 0.00</span>
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between">
                    <span>PAGO RECIBIDO</span>
                    <span className="float-right" id="resumen_total_igv">Q/ 0.00</span>
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                    <span>CAMBIO</span>
                    <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={handleSubmitFormaPago}
          >
            <FaPlusCircle />  Agregar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalFormaPago}
          >
            <FaTimes />  Cancelar
          </button>
        </div>
      </Modal>

    </div>
  );
}

FacturaPosForm.propTypes = {
  registroCliente: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
  datos: PropTypes.object,

  listarProductos: PropTypes.object,
  listadoPaises: PropTypes.object,
  listarPrecios: PropTypes.func,

  data: PropTypes.object,
  detalleProducto: PropTypes.object,
  eliminarLineaProducto: PropTypes.func,
  periodoD: PropTypes.object,

};

export default reduxForm({
  form: 'FormFacturaPdv',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      nombreCliente: validators.exists()('Este campo es requerido'),
    });
  },
})(FacturaPosForm);
