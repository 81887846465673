import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const PERIODOS = 'PERIODOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const YEAR = 'YEAR';
const FECHA_INICIO = 'FECHA_INICIO';
const FECHA_FIN = 'FECHA_FIN';
const ESTADO = 'ESTADO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let year = id_emp[7];
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/aguinaldo/mostrar_datos', params).then((response) => {
        dispatch({ type: PERIODOS, data: response.datos });
        dispatch({ type: YEAR, year: response.year });
        dispatch({ type: ESTADO, estado: response.estado });
        dispatch({ type: FECHA_INICIO, fecha_inicio: moment(response.fecha_inicio).format("DD/MM/YYYY") });
        dispatch({ type: FECHA_FIN, fecha_fin: moment(response.fecha_fin).format("DD/MM/YYYY") });
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
            history.goBack();
        }
        if (error.status === 400){
            NotificationManager.error(
                'Ocurrió un error al mostrar nomina',
                'Error',
                2000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
        }
        if (error.status === 400) {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reporteNomina = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    let reporte = 'reporteNomina';
    const params = { id, reporte, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_nomina.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Información',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                2000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const confirmarNomina = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let year = id_emp[7];
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/aguinaldo/confirmar_nomina', params).then(() => {
        NotificationManager.success(
            'Bono 14 guardado correctamente',
            'Exito',
            3000
        );
        let ruta = `/nomina/${id_emp[5]}`
            dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al guardar bono14',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actualizarNomina = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina/actualizar_nomina', params).then((response) => {
        dispatch({ type: PERIODOS, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al actualizar nomina',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteExcelBono = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let year = id_emp[7];
    let reporte = 'reporteExcelAguinaldo';
    const params = { id, year, reporte };
    
    dispatch(setLoader(true));
    api.getExcel('/aguinaldo', params).then((response) => {
        const blob = response;
        const fileName = 'Aguinaldo.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
                
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500){
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    getModulo,
    reporteNomina,
    confirmarNomina,
    actualizarNomina,
    reporteExcelBono
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PERIODOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [YEAR]: (state, { year }) => {
        return {
            ...state,
            year,
        };
    },
    [FECHA_INICIO]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio,
        };
    },
    [FECHA_FIN]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin,
        };
    },
    [ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    fecha_inicio: [],
    fecha_fin: [],
    estado: [],
};

export default handleActions(reducers, initialState)