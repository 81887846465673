import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { validar } from "../../../common/components/components/ValidarNit";
import Swal from 'sweetalert2';


const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADERC = 'LOADERC';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const NIVEL = 'NIVEL';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_INCONTERMS = 'LISTADO_INCONTERMS';


const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = { id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_prd/', params).then((response) => {
        const producto = [];
        response.forEach(item => {
            if (item.tipoPro === 'P') {
                producto.push({
                    value: item.id + ' # ' + item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({ type: PRODUCTOS, productosSelect: producto })
    })
}

export const listadoProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/producto', params).then((response) => {
        dispatch({ type: LISTADO_PRODUCTO, data: response });

    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/producto/empresa_productos_cod_inventario', params).then(data => {
        const list_producto = [];
        if (data) {
            data.forEach(item => {
                list_producto.push({
                    value: item.id + ' # ' + item.bodega + ' # ' + item.saldo,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Bodega: ' + item.numeroBodega + ' Existencia: ' + item.saldo
                })
            })
        }
        return list_producto;
    }).catch((error) => {
        return [];
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaPdv.values;
    console.log(data)

    if (datos.validar_nit === 'S' && data.tipoCliente === 'L') {
        // Registro CF
        if (data.identificadorCli === 'CF') {
            const formData = {
                identificador: data.identificadorCliente,
                nombre: data.nombreCliente,
                direccion: data.direccionCliente,
                telefono: data.telefonoCliente,
                correo: data.correoCliente,
                tipo_cliente: data.tipoCliente,

                // limite: data.limiteCredito,
                limite: 0,
                // dias_credito: data.diasCredito,
                dias_credito: 0,

                pais: data.paisCliente,
                modulo: 'PDV',
                listado_precios: datos.listadoPreciosCliente === 'S' ? data.listadoPreciosCliente.value : '',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        } else {
            // Registro Cliente con NIT
            let valid = validar.valNit(data.identificadorCliente);
            if (valid === false) {
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            } else {
                const formData = {
                    identificador: data.identificadorCliente,
                    nombre: data.nombreCliente,
                    direccion: data.direccionCliente,
                    telefono: data.telefonoCliente,
                    correo: data.correoCliente,
                    tipo_cliente: data.tipoCliente,

                    // limite: data.limiteCredito,
                    limite: 0,
                    // dias_credito: data.diasCredito,
                    dias_credito: 0,

                    pais: data.paisCliente,
                    modulo: 'PDV',
                    listado_precios: datos.listadoPreciosCliente === 'S' ? data.listadoPreciosCliente.value : '',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                    console.log( data );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }

    if (datos.validar_dpi === 'S' && data.tipoCliente === 'P') {
        let valid = validar.valCui(data.identificadorCliente);
        if (valid[0].validar === false) {
            Swal.fire({
                title: 'Cliente',
                text: 'DPI con formato inválido',
                type: 'error',
            })
        } else {
            const formData = {
                identificador: data.identificadorCliente,
                nombre: data.nombreCliente,
                direccion: data.direccionCliente,
                telefono: data.telefonoCliente,
                correo: data.correoCliente,
                tipo_cliente: data.tipoCliente,

                // limite: data.limiteCredito,
                limite: 0,
                // dias_credito: data.diasCredito,
                dias_credito: 0,

                pais: data.paisCliente,
                modulo: 'PDV',
                listado_precios: datos.listadoPreciosCliente === 'S' ? data.listadoPreciosCliente.value : '',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    if (datos.validar_nit === 'S' && data.tipoCliente === 'E') {
        const formData = {
            identificador: data.identificadorCliente,
            nombre: data.nombreCliente,
            direccion: data.direccionCliente,
            telefono: data.telefonoCliente,
            correo: data.correoCliente,
            tipo_cliente: data.tipoCliente,

            // limite: data.limiteCredito,
            limite: 0,
            // dias_credito: data.diasCredito,
            dias_credito: 0,

            pais: data.paisCliente,
            modulo: 'PDV',
            listado_precios: datos.listadoPreciosCliente === 'S' ? data.listadoPreciosCliente.value : '',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    if (datos.validar_nit === 'N' && data.tipoCliente === 'E') {
        const formData = {
            identificador: data.identificadorCliente,
            nombre: data.nombreCliente,
            direccion: data.direccionCliente,
            telefono: data.telefonoCliente,
            correo: data.correoCliente,
            tipo_cliente: data.tipoCliente,

            // limite: data.limiteCredito,
            limite: 0,
            // dias_credito: data.diasCredito,
            dias_credito: 0,

            pais: data.paisCliente,
            modulo: 'PDV',
            listado_precios: datos.listadoPreciosCliente === 'S' ? data.listadoPreciosCliente.value : '',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }

}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listadoPaises: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const listarPrecios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/listado_precios/empresa_listado_precios', params).then(data => {
        const list = [];
        if (data) {
            data.forEach(item => {
                list.push({
                    value: item.id,
                    label: item.nombre_lista
                })
            })
        }
        return list;
    }).catch((error) => {

        return [];
    })
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datosEmpresa: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}
export const actions = {
    getModulo,
    parametrosEmpresa,
    listarProductos,
    leerProductos,
    listarPaises,
    listarPrecios,
    listarClientes,
    registroCliente,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datosEmpresa }) => {
        return {
            ...state,
            datosEmpresa,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_PAISES]: (state, { listadoPaises }) => {
        return {
            ...state,
            listadoPaises,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    productosSelect: [],
    data: [],
    listadoPaises: [],

};

export default handleActions(reducers, initialState);
