import React, { Component } from 'react';
import Formulario from './AguinaldoForm';
import { withRouter } from 'react-router-dom';

class Aguinaldo extends Component{
    
    componentDidMount = () => {
        const { getModulo, listar } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listar();
    }
    render(){
        const { loader, data, modulo, fecha_inicio, 
            fecha_fin, year, reporteNomina, confirmarNomina,
            estado, reporteExcelBono } = this.props;
        
        return(
            <Formulario
                loader = {loader}
                modulo = {modulo}
                data = {data}
                fecha_inicio = {fecha_inicio}
                fecha_fin = {fecha_fin}
                reporteNomina = {reporteNomina}
                confirmarNomina = {confirmarNomina}
                estado = {estado}
                year = {year}
                reporteExcelBono = {reporteExcelBono}
            />
        );
    }
}
export default withRouter(Aguinaldo);
