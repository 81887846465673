import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import DetalleListado from './NominaList';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import { FaFileExcel, FaPrint } from 'react-icons/fa';
import { FaRedo } from 'react-icons/fa';

function AguinaldoForm(props) {
    const { modulo, data, year,
        fecha_inicio, 
        fecha_fin, 
        periodoD, 
        loader,
        reporteExcelBono,
        confirmarNomina, 
        estado
    } = props;
    let titulo = 'Aguinaldo';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const estilo = {
        overflow: "auto",
        width: "100%",

    };
    const editar = window.location.href.includes('editar');
    const crear = window.location.href.includes('crear');
    
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3" style={{ overflowX: "auto" }}>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4" style={{ minWidth: "1500px" }}>
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Periodo"
                                        type="text"
                                        autocomplete="off"
                                        name="periodo"
                                        className="form-control"
                                        value={year}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="periodo">
                                        Año
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha inicio"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_inicio}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha inicio
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha final"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_fin}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha final
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div style={estilo} className='mt-2 mb-3'>
                            <DetalleListado
                                loader={loader}
                                detalle={data}
                            />
                        </div>
                        
                        <div className='d-flex flex-row mt-3'>
                            {estado != 'F' &&
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => confirmarNomina()}
                                    >
                                        Guardar aguinaldo
                                    </button>
                                </div>
                            }
                            <a
                                href={`/#/nomina/${id_emp[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                            <button
                                className='btn btn-secondary mb-3 ml-4'
                                onClick={() => reporteExcelBono()}
                            >
                                <span><FaFileExcel /></span>Generar excel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AguinaldoForm.propTypes = {
    handleSubmit: PropTypes.func,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormAguinaldo',
})(AguinaldoForm);
