import React, { Component } from 'react';
import Grid from '../../Utils/GridNominaFiltroBono';
import moment from 'moment';

class NominaList extends Component{

    render(){
        const { loader, detalle } = this.props;
        return(
            <div>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } }
                >
                    <TableHeaderColumn
                        isKey
                        dataField="codigo"
                        width="100"
                        dataAlign='left'
                        dataSort
                        thStyle={
                            {color: 'white' }}
                        tdStyle={
                            {color: 'white' }}
                    >
                        Codigo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataAlign='left'
                        width="450"
                        dataSort
                        thStyle={
                            {color: 'white' }}
                        tdStyle={
                            {color: 'white' }}
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_alta"
                        headerAlign="center"
                        width="180"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            if (cell !== null) {
                                return moment(cell).format("DD/MM/YYYY");
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha ingreso
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="dias"
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Dias
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="diciembre"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Diciembre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="enero"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Enero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="febrero"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Febrero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="marzo"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Marzo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="abril"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Abril
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="mayo"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Mayo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="junio"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Junio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="julio"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Julio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="agosto"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Agosto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="septiembre"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Septiembre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="octubre"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Octubre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="noviembre"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Noviembre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="promedio"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Promedio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="total"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="prestamo"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Préstamo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        headerAlign="center"
                        dataAlign='right'
                        dataField="liquido"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00) {
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total a pagar
                    </TableHeaderColumn>
                </Grid>
                }
            </div>
        );
    }
}

export default NominaList;
