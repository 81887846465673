import React, { Component } from 'react';
import Formulario from './FacturacionPosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { useEffect } from 'react';

class FacturacionPos extends Component {
    //deshabilita el boton crear
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, parametrosEmpresa, listarPrecios, listarPaises, getModulo} = this.props;
        const id = match.params.id;
        if (id) {
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
        listarPaises();
        // useEffect(() => {
        //     const { match, leerB, listarTiposDocu, listarPaises, leerPeriodoD,
        //         getModulo, listarNiveles, vaciarBienes,
        //         leerEstablecimiento, parametrosEmpresa,
        //         listarMonedas, listarInconterms
        //     } = props;
            
        //     const id = match.params.id;
        //     const id_est = match.params.estable;

        //     if (id) {
        //         setCrear(false);
        //         leerB(id);
        //     }
        //     if (id === undefined) {
        //         leerEstablecimiento(id_est);
        //     }
        //     let ruta = window.location.href;
        //     let periodo = ruta.split('/');
        //     localStorage.removeItem('id_poliza_bienes_INV_FEL');
        //     leerPeriodoD(periodo[5], periodo[7]);
        //     getModulo(periodo[5], periodo[4]);
        //     parametrosEmpresa();
        //     listarTiposDocu();
        //     vaciarBienes();
        //     listarPaises();
        //     listarMonedas();
        //     listarNiveles();
        //     listarInconterms();
        // }, [])
    }

    render() {
        const { loader, modulo, datosEmpresa, listarProductos, listadoPaises, listarPrecios, registroCliente } = this.props;
        const { crear } = this.state;
        // Si crear esta habilitado ejecute registro bodega
        // const funcionEnvio = crear ? registroBodega : modificarBodega;
        return (
            <LoadMask loading={loader} dark>
                <Formulario
                    // onSubmit={funcionEnvio}
                    crear={crear}
                    modulo={modulo}
                    datos={datosEmpresa}
                    listarProductos={listarProductos}
                    listadoPaises={listadoPaises}
                    listarPrecios={listarPrecios}
                    registroCliente={registroCliente}
                />
            </LoadMask>
        );
    }
}

export default FacturacionPos;
