import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

// Elementos del formulario
import {
  renderField,
  renderNumber,
  renderFieldCheck,
  renderFieldCheckB,
  AsyncSelectField
} from "../../Utils/renderField/renderField";


function BodegasForm(props) {
  const { handleSubmit, crear, modulo, datos, listarSucursales } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Bodega' : 'Crear Bodega';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Bodega'
  }
  let autoFocus = true;

  const [mrp, setMrp] = useState(false);

  const onCheckboxClicked = (idx, isChecked) => {
    setMrp(isChecked);
  }
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2'>
              {/* Numero bodega */}
              <div className='col-2'>
                <Field
                  autoFocus={autoFocus}
                  name="numero"
                  placeholder="Numero bodega"
                  className="form-control"
                  component={renderNumber}
                  disabled={editar ? true : disabled}
                  msj="Numero bodega"
                />
              </div>
              {/* MRP */}
              <div className='col-2 mt-3'>
                {crear === true ? (
                  <Field
                    name="mrp"
                    label="MRP"
                    checked={mrp}
                    onChange={onCheckboxClicked}
                    component={renderFieldCheckB}
                  />
                ) :
                  <Field
                    disabled={disabled}
                    name="mrp"
                    label="MRP"
                    component={renderFieldCheck}
                  />
                }
              </div>
              {(crear === false && editar === false) && 
                <div className='col-8'>
                  <Field
                    name="sucursal"
                    placeholder="Establecimiento"
                    className="form-control"
                    component={renderField}
                    disabled={true}
                    msj="Establecimiento"
                  />
                </div>
              }
              {(crear === false && editar === true) && 
                <div className='col-8'>
                  <Field
                    name="sucursal"
                    placeholder="Establecimiento"
                    className="form-control"
                    component={renderField}
                    disabled={true}
                    msj="Establecimiento"
                  />
                </div>
              }
              {datos.punto_venta === 'S' && crear === true &&
                <div className='col-8'>
                  <div className="form-floating">
                    <Field
                      name="establecimiento"
                      placeholder="Establecimiento..."
                      component={AsyncSelectField}
                      loadOptions={listarSucursales}
                      className="form-select"
                      msj="Establecimiento"
                    />
                  </div>
                </div>
              }
            </div>
            {(crear === false && editar === true) && 
              <div className='row'>
                <div className='col-2'>
                </div>
                <div className='col-2'>
                </div>
                <div className='col-8'>
                  <div className="form-floating">
                    <Field
                      name="establecimiento_mod"
                      placeholder="Establecimiento..."
                      component={AsyncSelectField}
                      loadOptions={listarSucursales}
                      className="form-select"
                      msj="Establecimiento"
                    />
                  </div>
                </div>
              </div>
            }
            <div className='row mb-2'>
              {/* Bodega */}
              <div className='mb-2 col-6'>
                <Field
                  name="bodega"
                  placeholder="Bodega"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Bodega"
                />
              </div>
            </div>
            {/* Buttons */}
            <div className='d-flex flex-row mt-3'>
              {disabled == false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={handleSubmit}
                >
                  {editar ? 'Modificar' : 'Guardar'}
                </button>
              }
              {/* Regresar */}
              <a
                href={`/#/inventario/${id_emp[5]}/bodegas`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BodegasForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormBodega',
  validate: (data) => {
    return validate(data, {
      numero: validators.exists()('Este campo es requerido'),
      bodega: validators.exists()('Este campo es requerido'),
    });
  },
})(BodegasForm);
