import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import login from "./modules/cuenta/login";
import register from "./modules/cuenta/register";
import profile from "./modules/cuenta/profile";
import usuarios from "./modules/usuarios/usuarios";
import notificaciones from "./modules/notificaciones/notificaciones";
import empresa from "./modules/ajustes/empresa";
import establecimiento from "./modules/ajustes/establecimiento";
import nivel from "./modules/contabilidad/niveles";
import cuenta from "./modules/contabilidad/cuentasContables";
import poliza from "./modules/contabilidad/polizas";
import periodo from "./modules/periodos/periodos";
import centroCosto from "./modules/contabilidad/centroCosto";
import informe from "./modules/contabilidad/informes";
import tipo from "./modules/compras/tipoDocumento";
import proveedor from "./modules/compras/proveedores";
import compra from "./modules/compras/compras";
import liqGastos from "./modules/compras/liquidacionGastos";
import bancos from "./modules/bancos/bancos";
import cuentaBanco from "./modules/bancos/cuentaBancaria";
import cheques from "./modules/bancos/cheques";
import nota_debito from "./modules/bancos/notaDebito";
import tipoFac from "./modules/facturacion/tipoDocumento";
import clientes from "./modules/facturacion/clientes";
import factura from "./modules/facturacion/facturas";
import cambiarPass from "./modules/cuenta/cambiarPassword";
import nota_credito from "./modules/bancos/notaCredito";
import deposito from "./modules/bancos/depositos";
import informeC from "./modules/compras/informes";
import informeF from "./modules/facturacion/informes";
import importacion from "./modules/compras/importacion";
import informeB from "./modules/bancos/informes";
import bodegas from "./modules/inventario/bodegas";
import tipoProducto from "./modules/inventario/tipoProducto";
import presentacionProducto from "./modules/inventario/presentacionProducto";
import producto from "./modules/inventario/producto";
import unidadMedida from "./modules/inventario/unidad_medida";
import proyectos from "./modules/inmobiliaria/proyectos";
import subProyectos from "./modules/inmobiliaria/subProyectos";
import fase from "./modules/inmobiliaria/fases";
import material from "./modules/inmobiliaria/materiales";
import abono_cxc from "./modules/facturacion/abonos";
import entradas from "./modules/inventario/entradas";
import salidas from "./modules/inventario/salidas";
import informeI from "./modules/inventario/informes";
import abono_cxp from "./modules/compras/abonos";
import centro_trabajo from "./modules/produccion/centroTrabajo";
import fase_prd from "./modules/produccion/fase";
import insumo from "./modules/produccion/insumo";
import tipo_abono from "./modules/compras/tipoAbono";
import ingreso_prd from "./modules/produccion/ingreso";
import listado_precios from "./modules/facturacion/listadoPrecios";
import tools from "./modules/tools/tools";
import serie_prd from "./modules/produccion/serie";
import envio_prd from "./modules/produccion/envio";
import informe_prd from "./modules/produccion/informes";
import tipo_cambio from "./modules/tipoCambio/tipoCambio";
import tipo_abono_fac from "./modules/facturacion/tipoAbono";
import departamento from "./modules/recursos_humanos/departamentos";
import puesto from "./modules/recursos_humanos/puesto";
import colaborador from "./modules/recursos_humanos/colaborador";
import pais_rh from "./modules/recursos_humanos/pais";
import nota_cobro from "./modules/produccion/notaCobro";
import piloto from "./modules/produccion/piloto";
import consumo_prd from "./modules/produccion/consumo";
import factura_prd from "./modules/produccion/factura";
import factura_fel from "./modules/facturacion/facturaFEL";
import nota_credito_fel from "./modules/facturacion/notaCreditoFEL";
import nota_debito_fel from "./modules/facturacion/notaDebitoFEL";
import factura_especial_fel from "./modules/compras/facturaEspecialFEL";
import costo from "./modules/inventario/calcularCosto";
import devolucion_cajas from "./modules/produccion/devolucionCajas";
import ingreso_cajas from "./modules/produccion/ingresoCajas";
import auto_ventas from "./modules/auto_ventas/auto_ventas";
import auto_ventas_informes from "./modules/auto_ventas/informes";
import auto_ventas_ingresos from "./modules/auto_ventas/ingresos/ingresos";
import auto_ventas_ingresos_editar from "./modules/auto_ventas/ingresos/ingresosEditar";
import auto_ventas_ingresos_ver from "./modules/auto_ventas/ingresos/ingresosVer";
import auto_ventas_vendedores from "./modules/auto_ventas/vendedores/vendedores";
import auto_ventas_vendedores_editar from "./modules/auto_ventas/vendedores/vendedoresEditar";
import auto_ventas_vendedores_ver from "./modules/auto_ventas/vendedores/vendedoresVer";
import auto_ventas_ventas from "./modules/auto_ventas/ventas/ventas";
import auto_ventas_ventas_ver from "./modules/auto_ventas/ventas/ventasVer";
import auto_ventas_marcas from "./modules/auto_ventas/marcas";
import auto_ventas_tipo from "./modules/auto_ventas/tipo";
import auto_ventas_combustible from "./modules/auto_ventas/combustible";
import auto_ventas_linea from "./modules/auto_ventas/linea";
import laboratorio from "./modules/laboratorio/reducers";
import settings from "./modules/settings/reducers";
import finanzas from "./modules/finanzas/finanzas";
import informesRRHH from './modules/recursos_humanos/informes';
import moneda from './modules/ajustes/moneda';
import impuesto from './modules/ajustes/impuesto';
import rs_fabricante from './modules/registro_sanitario/fabricante';
import rs_forma from './modules/registro_sanitario/forma';
import rs_comercializador from './modules/registro_sanitario/comercializador';
import rs_estatus from './modules/registro_sanitario/estatus';
import rs_producto from './modules/registro_sanitario/producto';
import rs_via_admin from './modules/registro_sanitario/viaAdmin';
import rs_grupo from './modules/registro_sanitario/grupo';
import baja_rh from './modules/recursos_humanos/bajaColaborador';
import devolucion_producto from './modules/produccion/devolucionProducto';
import anticipo_cmp from './modules/compras/anticipos';
import anticipo_fac from './modules/facturacion/anticipos';
import liquidacion_rh from './modules/recursos_humanos/liquidacion';
import nota_credito_cxp from './modules/compras/notaCredito';
import invernaderos from './modules/produccion/invernaderos';
import distribucion_cajas from './modules/produccion/distribucionCajas';
import baja_cajas from './modules/produccion/bajaCajas';
import aumento_salario_rh from './modules/recursos_humanos/aumentoSalario';
import vacaciones_rh from './modules/recursos_humanos/vacaciones';
import promocion_puesto_rh from './modules/recursos_humanos/promocionPuesto';
import corte_vacaciones_rh from './modules/recursos_humanos/corteVacaciones';
import siembra_prd from './modules/produccion/siembra';
import fecha_siembra_prd from './modules/produccion/fechaCosecha';
import tipo_gasto_prd from './modules/produccion/tipoGasto';
import gasto_prd from './modules/produccion/gasto';
import conciliacion_bancaria from './modules/bancos/conciliacion';
import frecuencia_pago_nomina from './modules/nomina/frecuencia_pago';
import periodo_nomina from './modules/nomina/periodo';
import calculo_nomina from './modules/nomina/nomina';
import tipo_descuento_nomina from './modules/nomina/tipo_descuento';
import isr_rh from './modules/recursos_humanos/isr';
import hora_extra_nomina from './modules/nomina/horas_extras';
import descuento_nomina from './modules/nomina/descuentos';
import tipo_descuento_rrhh from './modules/recursos_humanos/tipo_descuento';
import descuento_fijo_rrhh from './modules/recursos_humanos/descuentoFijo';
import rs_linea from './modules/registro_sanitario/linea';
import rs_familia from './modules/registro_sanitario/familia';
import rs_acondicionador from './modules/registro_sanitario/acondicionador';
import rs_presentacion from './modules/registro_sanitario/presentacion';
import rs_clasificacion from './modules/registro_sanitario/clasificacion';
import rs_principioActivo from './modules/registro_sanitario/principioActivo';
import rs_concentracion from './modules/registro_sanitario/concentracion';
import rs_pais from './modules/registro_sanitario/pais';
import rs_registro_sanitario from './modules/registro_sanitario/registroSanitario';
import baja_igss_rh from './modules/recursos_humanos/bajaIgss';
import alta_igss_rh from './modules/recursos_humanos/altaIgss';
import prestamo_rh from './modules/recursos_humanos/prestamo';
import falta_rh from './modules/recursos_humanos/falta';
import judicial_rh from './modules/recursos_humanos/judicial';
import lc_poliza from './modules/libros_contables/polizas';
import lc_informe from './modules/libros_contables/informes';
import rs_licencia from './modules/registro_sanitario/licencia';
import rs_tipo_registro from './modules/registro_sanitario/tipoRegistro';
import lc_intercambioCuenta from './modules/libros_contables/intercambioCuentas';
import otros_gastos_nomina from './modules/nomina/nomina_otros_ingresos';
import informesNomina from './modules/nomina/informes';
import cierre_mes_nomina from './modules/nomina/cierre_mes';
import informesRegistroSanitario from './modules/registro_sanitario/informes';
import recibo_caja_prd from './modules/produccion/reciboCaja';
import corte_caja_prd from './modules/produccion/corteCaja';
import deposito_recibo_prd from './modules/bancos/depositoRecibo';
import agente_rm from './modules/registro_marca/agente';
import estatus_rm from './modules/registro_marca/estatus';
import titular_rm from './modules/registro_marca/titular';
import observacion_rm from './modules/registro_marca/observacion';
import comercial_rm from './modules/registro_marca/comercializada';
import principioActivo_rm from './modules/registro_marca/principioActivo';
import registro_marca_rm from './modules/registro_marca/registroMarca';
import factura_fel_BS from './modules/facturacion/facturaFEL_BS';
import factura_farm_fel from './modules/facturacion/facturaFarmFEL';
import nota_credito_cxc from './modules/facturacion/notaCredito';
import traslado_cajas from './modules/produccion/trasladoCajas';
import calculo_bono from './modules/nomina/bono14';
import rs_elzm from './modules/registro_sanitario/elzm';
import factura_farm from './modules/facturacion/facturaFarm';
import rs_registro_coempaque from './modules/registro_sanitario/registroCoempaque';
import vm_carga_compras from './modules/visitador_medico/compras';
import vm_tipo from './modules/visitador_medico/tipo';
import vm_centro_costo from './modules/visitador_medico/centro_costo';
import vm_departamento from './modules/visitador_medico/departamento';
import vm_producto from './modules/visitador_medico/producto';
import vm_visitador from './modules/visitador_medico/visitador';
import vm_liquidacion from './modules/visitador_medico/liquidacion';
import cmp_orden_compra from './modules/compras/ordenCompra';
import inv_ingreso_inventario from './modules/inventario/ingresoInventario';
import inv_orden_servicio from './modules/inventario/ordenServicio';
import inv_tipo_gasto from './modules/inventario/tipoGasto';
import inv_gasto_proyecto from './modules/inventario/gastoProyecto';
import inv_cierre_proyecto from './modules/inventario/cierreProyecto';
import inv_consumo_producto from './modules/inventario/consumoProducto';
import vm_otros_gastos from './modules/visitador_medico/otros_gastos';
import vm_cuenta_contable from './modules/visitador_medico/cuenta_contable';
import vm_generar_poliza from './modules/visitador_medico/generar_poliza';
import fac_generar_poliza from './modules/facturacion/polizaParqueo';
import cuenta_contable_prd from './modules/produccion/cuentaContable';
import informeVM from "./modules/visitador_medico/informes";
import prd_consolidacion_envio from "./modules/produccion/consolidacionEnvio";
import informeRM from "./modules/registro_marca/informes";
import calculo_aguinaldo from './modules/nomina/aguinaldo';

/* Punto de venta */
import bodegas_pv from './modules/punto_venta/bodegas';
import clientes_pv from './modules/punto_venta/clientes';
import presentacion_pv from './modules/punto_venta/presentacionProducto';
import productos_pv from './modules/punto_venta/producto';
import tipo_producto_pv from './modules/punto_venta/tipoProducto';
import unidad_medida_pv from './modules/punto_venta/unidad_medida';
import facturacionPos_pv from './modules/punto_venta/facturacionPos';

export default combineReducers({
    form: formReducer,
    settings,
    login,
    register,
    profile,
    usuarios,
    routing,
    empresa,
    establecimiento,
    nivel,
    cuenta,
    poliza,
    periodo,
    centroCosto,
    notificaciones,
    informe,
    tipo,
    proveedor,
    compra,
    liqGastos,
    bancos,
    cuentaBanco,
    cheques,
    nota_debito,
    tipoFac,
    clientes,
    factura,
    cambiarPass,
    nota_credito,
    deposito,
    informeC,
    informeF,
    importacion,
    informeB,
    bodegas,
    tipoProducto,
    presentacionProducto,
    producto,
    unidadMedida,
    proyectos,
    subProyectos,
    fase,
    material,
    abono_cxc,
    entradas,
    salidas,
    informeI,
    abono_cxp,
    centro_trabajo,
    fase_prd,
    insumo,
    tipo_abono,
    ingreso_prd,
    listado_precios,
    tools,
    serie_prd,
    envio_prd,
    informe_prd,
    tipo_cambio,
    tipo_abono_fac,
    departamento,
    puesto,
    colaborador,
    pais_rh,
    nota_cobro,
    piloto,
    consumo_prd,
    factura_prd,
    factura_fel,
    nota_credito_fel,
    nota_debito_fel,
    factura_especial_fel,
    costo,
    devolucion_cajas,
    ingreso_cajas,
    auto_ventas,
    auto_ventas_informes,
    auto_ventas_ingresos,
    auto_ventas_ingresos_editar,
    auto_ventas_ingresos_ver,
    auto_ventas_vendedores,
    auto_ventas_vendedores_editar,
    auto_ventas_vendedores_ver,
    auto_ventas_ventas,
    auto_ventas_ventas_ver,
    auto_ventas_marcas,
    auto_ventas_tipo,
    auto_ventas_combustible,
    auto_ventas_linea,
    finanzas,
    laboratorio,
    informesRRHH,
    moneda,
    impuesto,
    rs_fabricante,
    rs_forma,
    rs_comercializador,
    rs_estatus,
    rs_producto,
    rs_via_admin,
    rs_grupo,
    baja_rh,
    devolucion_producto,
    anticipo_cmp,
    anticipo_fac,
    liquidacion_rh,
    nota_credito_cxp,
    invernaderos,
    distribucion_cajas,
    baja_cajas,
    aumento_salario_rh,
    vacaciones_rh,
    promocion_puesto_rh,
    corte_vacaciones_rh,
    siembra_prd,
    fecha_siembra_prd,
    tipo_gasto_prd,
    gasto_prd,
    conciliacion_bancaria,
    frecuencia_pago_nomina,
    periodo_nomina,
    calculo_nomina,
    tipo_descuento_nomina,
    isr_rh,
    hora_extra_nomina,
    descuento_nomina,
    tipo_descuento_rrhh,
    descuento_fijo_rrhh,
    rs_linea,
    rs_familia,
    rs_acondicionador,
    rs_presentacion,
    rs_clasificacion,
    rs_principioActivo,
    rs_concentracion,
    rs_pais,
    rs_registro_sanitario,
    baja_igss_rh,
    alta_igss_rh,
    prestamo_rh,
    falta_rh,
    judicial_rh,
    lc_poliza,
    lc_informe,
    rs_licencia,
    rs_tipo_registro,
    lc_intercambioCuenta,
    otros_gastos_nomina,
    informesNomina,
    cierre_mes_nomina,
    informesRegistroSanitario,
    recibo_caja_prd,
    corte_caja_prd,
    deposito_recibo_prd,
    agente_rm,
    estatus_rm,
    titular_rm,
    observacion_rm,
    comercial_rm,
    principioActivo_rm,
    registro_marca_rm,
    factura_fel_BS,
    factura_farm_fel,
    nota_credito_cxc,
    traslado_cajas,
    calculo_bono,
    rs_elzm,
    factura_farm,
    rs_registro_coempaque,
    vm_carga_compras,
    vm_tipo,
    vm_centro_costo,
    vm_departamento,
    vm_producto,
    vm_visitador,
    vm_liquidacion,
    cmp_orden_compra,
    inv_ingreso_inventario,
    inv_orden_servicio,
    inv_tipo_gasto,
    inv_gasto_proyecto,
    inv_cierre_proyecto,
    inv_consumo_producto,
    vm_otros_gastos,
    vm_cuenta_contable,
    vm_generar_poliza,
    fac_generar_poliza,
    cuenta_contable_prd,
    informeVM,
    prd_consolidacion_envio,
    informeRM,
    calculo_aguinaldo,
    // *** PUNTO DE VENTA *** // 
    bodegas_pv,
    clientes_pv,
    presentacion_pv,
    productos_pv,
    tipo_producto_pv,
    unidad_medida_pv,
    facturacionPos_pv,
});
