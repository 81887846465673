import React from 'react';
import styled from 'styled-components';
import * as AiIcons from "react-icons/ai";
import "../../../../style/PuntoVenta/index.css"

const Modal = ({ children, estadoModal,handleClose, titulo, layoutColumn}) => {
    return (
        <React.Fragment>
            {estadoModal &&
                <div className="container-fluid overlayModal">
                    <div className={`col-12 col-lg-${layoutColumn} col-md-${layoutColumn+2} container-sm contenidoModal`}>
                    {/* <div className="col-12 container-sm contenidoModal"> */}
                        <div className="row bg-dark text-white rounded">
                            <div className="card-header bg-dark d-flex justify-content-between align-content-center m-0 py-3">
                                <h5 className="card-title p-0 m-0">{titulo}</h5>
                                <button type="button" onClick={handleClose} className="btn-success btn-close p-2"></button>
                            </div>

                            {children}

                            {/* <div className="card-footer bg-dark text-center py-3">
                                <button type="button" className="btn btn-primary mx-1" onClick={handleSubmit}>Guardar</button>
                                <button type="button" className="btn btn-secondary mx-1" onClick={handleClose}>Cerrar</button>
                            </div> */}
                        </div>
                    </div>
                </div>

            }
        </React.Fragment>
    );
}

export default Modal;
