import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/aguinaldo';
import Aguinaldo from './Aguinaldo';

const ms2p = (state) => {
  return {
    ...state.calculo_aguinaldo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Aguinaldo);