import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';

import {
    renderNumber,
} from "../../Utils/renderField/renderField";

function SeleccionarFormPeriodo() {
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const [year, setYear] = useState(0);

    const handleChange = e => {
        setYear(e);
    }
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingrese año para Aguinaldo"
                botonCerrar="false"
            >
                <Contenido>
                    <div className='col-4 mb-3'>
                        <Field
                            name="anio"
                            placeholder="Año"
                            className="form-control"
                            component={renderNumber}
                            onChange={(e, value) => {
                                handleChange(value);
                            }}
                            msj="Año"
                        />
                    </div>
                    <a
                        href={`/#/nomina/${id_emp[5]}/aguinaldo/${year}`}
                        className='btn mr-2 mb-3 btn-primary'
                    >
                        Confirmar
                    </a>
                </Contenido>
            </Modal>
        </div>
    );

}
SeleccionarFormPeriodo.propTypes = {
    periodoL: PropTypes.string.isRequired,
    listaEs: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'FormYearAguinaldo',
})(SeleccionarFormPeriodo);



const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;